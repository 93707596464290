@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
.karla {
  font-family: "Karla", sans-serif;
}
::selection {
  background: #f59e0b;
  color: #fff;
}
::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  background: rgb(241 245 249);
}
::-webkit-scrollbar-thumb {
  background: #f59e0b;
  transition: 0.3s ease;
  border: 3px solid rgb(241 245 249);
  border-radius: 100vh;
}
::-webkit-scrollbar-thumb:hover {
  background: #da8d0a;
}
